import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import "animate.css";
import nextElementInList from "./utils/nextElementInList";
import TrackVisibility from "react-on-screen";

export const Banner = () => {
  const [action, setAction] = useState("Web Developer");

  useEffect(() => {
    const actions = ["Web Developer", "Web Designer", "UI/UX Designer"];
    const interval = setInterval(() => {
      console.log("Changing action...");
      setAction((prevAction) => {
        const nextAction = nextElementInList(actions, prevAction);
        console.log("Next action:", nextAction);
        return nextAction;
      });
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h1>
                    {`Hi, I'm Alex`} <br />{" "}
                    <span
                      className="txt-rotate"
                      dataPeriod="1000"
                      data-rotate='[ "Web Developer", "Web Designer", "UI/UX Designer" ]'
                    >
                      <span className="wrap">{action}</span>
                    </span>
                  </h1>
                  <p>
                    🚀 Hello World! I&apos;m a seasoned Frontend Developer with
                    a passion for crafting immersive digital experiences. My
                    journey begins with the foundational trio: HTML, CSS, and
                    JavaScript, where I meticulously design and breathe life
                    into user interfaces. <br className="sm:block hidden" />
                    <br className="sm:block hidden" />
                    💡 Proficient in Vue.js and React, I specialize in creating
                    dynamic and responsive applications that elevate user
                    engagement. Whether it&apos;s architecting single-page
                    applications or building complex, interactive UI components,
                    I thrive on pushing the boundaries of frontend development.
                    <br className="sm:block hidden" />
                    <br className="sm:block hidden" />
                    🌐 Beyond the client-side realm, I navigate the backend
                    landscape using Node.js, seamlessly integrating databases to
                    ensure robust and scalable solutions. Data management and
                    server-side logic are not just challenges but exciting
                    puzzles to solve.
                    <br className="sm:block hidden" />
                    <br className="sm:block hidden" /> 🎨 Tailwind and Bootstrap
                    are my go-to styling companions, helping me sculpt sleek and
                    visually appealing designs. For those who seek an extra
                    layer of depth, I delve into Three.js, transforming websites
                    into immersive 3D spaces that captivate audiences.
                    <br className="sm:block hidden" />
                    <br className="sm:block hidden" /> 🐍 Adding to the arsenal,
                    my proficiency in Python brings versatility to the table,
                    allowing me to explore various dimensions of web
                    development. Whether it&apos;s scripting, automation, or
                    server-side applications, Python enhances the breadth of my
                    capabilities. Let&apos;s embark on a journey to redefine
                    digital landscapes and create web experiences that resonate!
                    💻✨ #FrontendMaestro #CodeCraftsman
                  </p>
                  {/* <button onClick={() => console.log("connect")}>
                    Let’s Connect <ArrowRightCircle size={25} />
                  </button> */}
                </div>
              )}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                >
                  <img src={headerImg} alt="Header Img" />
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
