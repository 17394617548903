import css from "../assets/img/css.png";
import docker from "../assets/img/docker.png";
import figma from "../assets/img/figma.png";
import html from "../assets/img/html.png";
import git from "../assets/img/git.png";
import javascript from "../assets/img/javascript.png";
import mongodb from "../assets/img/mongodb.png";
import nodejs from "../assets/img/nodejs.png";
import python from "../assets/img/python.png";
import reactjs from "../assets/img/reactjs.png";
import redux from "../assets/img/redux.png";
import tailwind from "../assets/img/tailwind.png";
import threejs from "../assets/img/threejs.svg";
import typescript from "../assets/img/typescript.png";
import vuejs from "../assets/img/vuejs.png";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSharp from "../assets/img/color-sharp.png";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills.</h2>
              <p>
                Each skill is a brushstroke in my canvas of proficiency,
                crafting innovative solutions. <br></br>Beyond icons, it's a
                visual journey through my diverse skills. <br></br> <br></br>
                Take a spin and discover the technologies fueling my passion for
                digital experiences.
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="item">
                  <img src={html} alt="html-logo" />
                  <h5>HTML</h5>
                </div>
                <div className="item">
                  <img src={css} alt="css-logo" />
                  <h5>CSS</h5>
                </div>
                <div className="item">
                  <img src={javascript} alt="javascript-logo" />
                  <h5>JavaScript</h5>
                </div>
                <div className="item">
                  <img src={vuejs} alt="vue-logo" />
                  <h5>Vue.js</h5>
                </div>
                <div className="item">
                  <img src={reactjs} alt="react-logo" />
                  <h5>React</h5>
                </div>
                <div className="item">
                  <img src={nodejs} alt="node.js-logo" />
                  <h5>Node.js</h5>
                </div>
                <div className="item">
                  <img src={threejs} alt="three.js-logo" />
                  <h5>Three.js</h5>
                </div>
                <div className="item">
                  <img src={typescript} alt="typescript-logo" />
                  <h5>TypeScript</h5>
                </div>
                <div className="item">
                  <img src={tailwind} alt="tailwind-logo" />
                  <h5>Tailwind</h5>
                </div>
                <div className="item">
                  <img src={python} alt="python-logo" />
                  <h5>Python</h5>
                </div>
                <div className="item">
                  <img src={mongodb} alt="mongoDB-logo" />
                  <h5>MongoDB</h5>
                </div>
                <div className="item">
                  <img src={redux} alt="redux-logo" />
                  <h5>Redux</h5>
                </div>
                <div className="item">
                  <img src={docker} alt="docker-logo" />
                  <h5>Docker</h5>
                </div>
                <div className="item">
                  <img src={figma} alt="figma-logo" />
                  <h5>Figma</h5>
                </div>
                <div className="item">
                  <img src={git} alt="git-logo" />
                  <h5>Git</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img
        className="background-image-left"
        src={colorSharp}
        alt="ImageBackground"
      />
    </section>
  );
};
