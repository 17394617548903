import React from "react";
import { Col } from "react-bootstrap";
import githubLogo from "../assets/img/github.png";

export const ProjectCard = ({
  title,
  description,
  imgUrl,
  source_code_link,
}) => {
  return (
    <Col xs={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt={`${title} project`} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          {source_code_link && (
            <a
              href={source_code_link}
              target="_blank"
              rel="noopener noreferrer"
              className="github-link"
            >
              <img src={githubLogo} alt="GitHub Logo" className="github-logo" />
            </a>
          )}
        </div>
      </div>
    </Col>
  );
};
