import { Container, Row, Col } from "react-bootstrap";

import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6} xs={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} xs={6} className="text-center text-sm-end">
            <div className="social-icon-footer">
              <a
                href="https://www.linkedin.com/in/alex-russotto-7a2r93/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={navIcon1} alt="LinkedIn" />
              </a>
              <a
                href="https://github.com/TaektuCode"
                target="_blank"
                rel="noreferrer"
              >
                <img src={navIcon2} alt="GitHub" />
              </a>
              <a
                href="https://www.codewars.com/users/TaektuCode"
                target="_blank"
                rel="noreferrer"
              >
                <img src={navIcon3} alt="Codewars" />
              </a>
            </div>
            <p className="copyright">Copyright 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
