import { Container, Row, Col, Tab } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import joketeller from "../assets/img/projects/joketeller.png";
import quote_generator from "../assets/img/projects/quote_generator.png";
import infinity_scroll from "../assets/img/projects/infinity_scroll.png";
import hangman from "../assets/img/projects/hangman.png";
import guessmynum from "../assets/img/projects/guessmynumber.png";
import picinpic from "../assets/img/projects/pictureinpicture.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const projects = [
    {
      title: "Joke Teller",
      description:
        "This JavaScript app fetches programming jokes from JokeAPI, converting them into speech with VoiceRSS on button click. Event listeners enable fetching and re-enable the button post-audio playback, enhancing user engagement with entertaining text-to-speech programming humor.",
      imgUrl: joketeller,
      source_code_link: "https://github.com/TaektuCode/joke-teller",
    },
    {
      title: "Quote Generator",
      description:
        "This JavaScript app dynamically fetches and displays quotes from an API, allowing users to generate new quotes and share them on Twitter. The clean interface includes a loading spinner for a seamless experience. It's a simple and engaging way to discover and share inspirational quotes. ",
      imgUrl: quote_generator,
      source_code_link: "https://github.com/TaektuCode/Quote-Generator",
    },
    {
      title: "Infinity Scroll",
      description:
        "This JavaScript app dynamically fetches and displays Unsplash API photos as users scroll down. Utilizing event listeners, it loads more images near the bottom. The code efficiently manages API responses, updates the URL, and displays images. Enhancing user experience, it creates an infinite scrolling gallery. ",
      imgUrl: infinity_scroll,
      source_code_link: "https://github.com/TaektuCode/Infinity-Scroll",
    },
    {
      title: "Hangman",
      description:
        "This JavaScript Hangman game lets users guess a word by clicking letters, updating the interface dynamically. It includes a victory or defeat modal and a play-again button for restarting. An interactive way to test word-guessing skills.",
      imgUrl: hangman,
      source_code_link: "https://github.com/TaektuCode/hangman-app",
    },
    {
      title: "Picture in Picture",
      description:
        "This JavaScript app captures your screen with navigator.mediaDevices.getDisplayMedia(), displays it in a video player, and easily switches to Picture-in-Picture mode. The code, featuring strict mode and seamless error handling, highlights modern web development skills. Perfect for screen sharing and PiP enjoyment!",
      imgUrl: picinpic,
      source_code_link: "https://github.com/TaektuCode/picture-in-picture",
    },
    {
      title: "Guess My Number",
      description:
        "This JavaScript Guess My Number game challenges players to guess a number between 1 and 20. The dynamic interface updates with feedback on each guess. A correct guess triggers a celebratory message and potential high score update. It's a simple, engaging way to test numerical intuition.",
      imgUrl: guessmynum,
      source_code_link: "https://github.com/TaektuCode/guess-my-number",
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Projects.</h2>
                  <p>
                    Following projects showcases my skills and experience
                    through real-world examples of my work. Each project is
                    briefly described with links to code repositories and live
                    demos in it. It reflects my ability to solve complex
                    problems, work with different technologies, and manage
                    projects effectively.
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    {/* <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">Tab 1</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Tab 2</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Tab 3</Nav.Link>
                      </Nav.Item>
                    </Nav> */}
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="section">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Cumque quam, quod neque provident velit, rem
                          explicabo excepturi id illo molestiae blanditiis,
                          eligendi dicta officiis asperiores delectus quasi
                          inventore debitis quo.
                        </p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Cumque quam, quod neque provident velit, rem
                          explicabo excepturi id illo molestiae blanditiis,
                          eligendi dicta officiis asperiores delectus quasi
                          inventore debitis quo.
                        </p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img
        className="background-image-right"
        src={colorSharp2}
        alt="background"
      ></img>
    </section>
  );
};
