import { useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import emailjs from "@emailjs/browser";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Contact = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    message: "",
  });

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      name: "",
      email: "",
      message: "",
    };

    // Validate Name
    if (form.name.length < 2) {
      newErrors.name = "Name must be at least 2 characters";
      valid = false;
    }

    // Validate Email
    if (!form.email.includes("@")) {
      newErrors.email = "Email must contain '@'";
      valid = false;
    }

    // Validate Message
    if (form.message.length < 1) {
      newErrors.message = "Message must contain at least 1 character";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });

    // Clear validation error for the current field when the user starts typing
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form before submitting
    if (validateForm()) {
      setLoading(true);

      emailjs
        .send(
          "service_d62xpzs",
          "template_gprnb1a",
          {
            from_name: form.name,
            to_name: "Alex",
            from_email: form.email,
            to_email: "russottoalex.studium@gmail.com",
            message: form.message,
          },
          "HRgnkKfg_MZn8tqpp",
        )
        .then(
          () => {
            setLoading(false);
            alert("Thank you. I will get back to you as soon as possible");

            setForm({
              name: "",
              email: "",
              message: "",
            });
          },
          (error) => {
            setLoading(false);
            console.log(error);
            alert("Sorry! Something went wrong.");
          },
        );
    }
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <img
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                  src={contactImg}
                  alt="Contact Us"
                />
              )}
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Contact.</h2>
                  <form ref={formRef} onSubmit={handleSubmit}>
                    <Row>
                      <Col size={12} sm={12} className="px-1">
                        <input
                          type="text"
                          name="name"
                          value={form.name}
                          onChange={handleChange}
                          placeholder="What's your name?"
                        />
                        <div className="error">{errors.name}</div>
                      </Col>

                      <Col size={12} sm={12} className="px-1">
                        <input
                          type="email"
                          name="email"
                          value={form.email}
                          onChange={handleChange}
                          placeholder="What's your email?"
                        />
                        <div className="error">{errors.email}</div>
                      </Col>

                      <Col size={12} className="px-1">
                        <textarea
                          rows="7"
                          name="message"
                          value={form.message}
                          onChange={handleChange}
                          placeholder="What's do you want to say?"
                        ></textarea>
                        <div className="error">{errors.message}</div>
                        <button type="submit">
                          <span>{loading ? "Sending..." : "Send"}</span>
                        </button>
                      </Col>
                    </Row>
                  </form>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
